import React from "react";
import HeroBanner from './HeroBanner';
import About from './About/About';
import Services from './Services/Services';
import HomeContact from "./HomeContact/HomeContact";
import Gallery from './Gallery/Gallery';
import LogoSlider from './LogoSlider/LogoSlider';
import { Helmet } from "react-helmet";

function Home() {
    
    return (
        <div className='home'>
            <Helmet>
                <title>Empowering Energy Solutions | E&I Engineers</title>
                <meta
                name="description"
                content="
                    Welcome to E&I Engineers, your trusted partner for comprehensive electrical engineering services. From turnkey projects to maintenance, we empower energy solutions with expertise and innovation."
                />
            </Helmet>
            <div className="chkScrollEl">
                <HeroBanner/>
                <About />
                <Services />
                <Gallery />
                <LogoSlider />
                <HomeContact />
            </div>
        </div>
    )
}

export default Home;