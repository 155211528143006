import React, { useState, useEffect } from 'react';
import './GoToTop.scss';

const ScrollToTop = () => {
  const [isVisible, setIsVisible] = useState(false);

  // Handle the scroll event
  const toggleVisibility = () => {
    if (window.pageYOffset > 300) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  // Scroll to the top of the page
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    window.addEventListener('scroll', toggleVisibility);
    return () => {
      window.removeEventListener('scroll', toggleVisibility);
    };
  }, []);

  return (
    <div className="scroll-to-top">
      {isVisible && (
        <button onClick={scrollToTop} className="scroll-button">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" color="#386873" fill="none">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M12.0027 3.125C11.478 3.125 11.0834 3.39329 10.7767 3.68621C10.4911 3.95899 10.153 4.38826 9.80903 4.82504L9.01041 5.83881C8.42543 6.58132 7.93577 7.20286 7.63564 7.72013C7.33377 8.24039 7.08851 8.88006 7.38216 9.54328C7.68382 10.2246 8.33594 10.4467 8.92273 10.5369C9.45953 10.6194 10.1675 10.6247 11 10.625V19.875C11 20.4273 11.4477 20.875 12 20.875C12.5523 20.875 13 20.4273 13 19.875V10.625C13.834 10.6247 14.5432 10.6195 15.0807 10.5369C15.6675 10.4467 16.3196 10.2246 16.6213 9.54328C16.9149 8.88006 16.6696 8.24039 16.3678 7.72013C16.0677 7.20286 15.578 6.58134 14.993 5.83884L14.1944 4.82504C13.8504 4.38826 13.5123 3.95899 13.2267 3.68621C12.9201 3.39329 12.5274 3.125 12.0027 3.125Z" fill="currentColor" />
</svg>
        </button>
      )}
    </div>
  );
};

export default ScrollToTop;
