import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { NavLink } from "react-router-dom";
import { navData } from "./HeaderData";
import "./Header.scss";
import OutsideAlerter from "./OutsideAlerter";
import ImageComponent from '../ImageComponent';
import { SvgLogoRegular1} from '../svgs';

function Header() {
  const [active, setActive] = useState(false);
  const [scrolled, setScrolled] = useState(false);
  const location = useLocation();

  const handleClick = () => setActive(!active);

  useEffect(() => {
    const handleScroll = () => {
      const isScrolled = window.scrollY > 100;
      setScrolled(isScrolled);
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <header className={`${location.pathname === '/' ? (scrolled ? 'white-bg' : 'transparent-bg') : 'white-bg'}`}>
      <nav className="navbar">
        <div className="navbar-logo">
        <OutsideAlerter>
          <Link to="/">
            <ImageComponent SvgIcon={SvgLogoRegular1} width="320px" height="200px" />
          </Link>
          </OutsideAlerter>
        </div>
        <OutsideAlerter>
          <div className="menu-icon" onClick={handleClick}>
            <i className={active ? "i-close i-cross" : "i-close i-barger"}></i>
          </div>
        </OutsideAlerter>
        <ul className={`navMenu ${active ? 'active' : ''}`}>
          {navData.map((item, index) => (
            <li key={index} className="nav-links">
              <OutsideAlerter>
              <NavLink exact activeClassName="active" to={item.url} onClick={handleClick}>
                {item.title}
              </NavLink>
              </OutsideAlerter>
            </li>
          ))}
        </ul>
      </nav>
    </header>
  );
}

export default Header;