import React from 'react';
import './Contact.scss';
import ImageComponent from '../../ImageComponent';
import { SvgIconContact1,SvgIconContact2} from '../../svgs';
import BannerInner from "../BannerInner";
import { Helmet } from "react-helmet";

const ContactComponent = ({ title1, title2, title3}) => {
    return (
        <>
        <Helmet>
            <title>Contact Us | E&I Engineers</title>
            <meta
            name="description"
            content="Get in touch with E&I Engineers for all your electrical engineering needs. Our professional staff is available 24/7 to provide support and answer your inquiries. Contact us today!"
            />
        </Helmet>
        <section className='bannerMain'>
            <BannerInner 
                imgLarge='./contctus-large.jpg'
                imgSmall="./contctus-small.jpg"
                imgTitle="Get in Touch with E&I Engineers"
            />
        </section>
        {
          <h2 className='slide-bottom ' id='headTitle'>
              <span id='title1'>{title1}</span>
              <span id='title2'>{title2} <span>{title3}</span></span>
          </h2>
          }
          <div className='contact__container'>
            <div className='contact-info'>
              <div className='contact-item'>
                <ImageComponent SvgIcon={SvgIconContact1} width="50px" height="50px" />
                <div className='txtPan'>
                  <p>Call to ask any question</p>
                  <a href="tel:+919347969458">+91 9347 9694 58</a> <br />
                  <a href="tel:+919059239458">+91 9059 2394 58</a>
                </div>
              </div>
              <div className='contact-item'>
                  <ImageComponent SvgIcon={SvgIconContact2} width="50px" height="50px" />
                  <div className='txtPan'>
                    <p>Email to get free quote</p>
                    <a href="mailto:azahar.shaikh3@gmail.com">azahar.shaikh3@gmail.com</a>
                  </div>
              </div>
            </div>
            <iframe
            src="https://19107382ea2a.quillforms.app/forms/simple-contact-form/"
            width="100%"
            height="800px"
            title="Iframe Contact Form"
            style={{ border: 'none' }}
            />
          </div>
        </>
      
    )
};

export default ContactComponent;