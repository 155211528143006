
import React, { useEffect } from "react";
import {Link} from 'react-router-dom' ;
import ImageComponent from './../../ImageComponent';
import {circleDownArrow} from './../../svgs'

function HeroBanner() {
    const scrollToAbout = () => {
        const aboutSection = document.getElementById('about');
        if (aboutSection) {
        const offset = 100;
        const elementPosition = aboutSection.getBoundingClientRect().top;
        const offsetPosition = elementPosition + window.pageYOffset - offset;
        
        window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth'
        });
        }
        };
    useEffect(() => {
        const video = document.getElementById('myVideo');
        if (video) {
            video.muted = true;
            video.autoplay = true;
        }
    }, []);
    return (
        <div className="heroBanner">
            <div className="video">
                <video id="myVideo" autoplay muted>
                    <source src="./heroBanner/heroBanner2.mp4" type="video/mp4" />
                </video>
            </div>
            <div className="textPan">
                <div className="text">
                    <h1>
                        <span className="slide-left">Empowering energy through</span>
                        <span id="d-block">
                            <span className="colorTxt slide-right"> Electrical & Instrumentation</span>
                        </span>
                    </h1>
                </div>
                <div className="cta">
                    <Link to="/About" className="learn-more">
                        <span className="circle" aria-hidden="true">
                            <span className="icon arrow"></span>
                        </span>
                        <span className="btnTxt">Read more</span>
                    </Link>
                </div>
            </div>
            <div className='scrollToAbout slide-bottom-infinite' onClick={scrollToAbout}>
                <ImageComponent SvgIcon={circleDownArrow} className="quote-icon"/>
            </div>
        </div>
    );
}
export default HeroBanner;
