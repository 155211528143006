import React from 'react';
import ContactComponent from '../Contact/ContactComponent';

const Contact = () => {
    return (
        <section className='contact'>
            <ContactComponent 
                title1=''
                title2="We're Here to Help"
                title3="Contact Us Anytime!"
            />
        </section>
    )
};

export default Contact;