export const Data=[
    
    {
        id:1,
        img:'./mentoring-duotone-rounded.svg',
        title:"Expertise and Services",
        para:"Our expertise spans Design and Detailed Engineering, Electrical Services, Instrumentation and Control, Pre-Planning and Execution Planning, Procurement, Construction Management and Supervision, Field Engineering and Coordination, Erection, Testing and Commissioning, Test or Process Instrument Calibration, As-Built Documentation, Constructability Reviews, Training, and comprehensive project handover along with Operation and Maintenance"
    },
    {
        id:2,
        img:'./user-group-duotone-rounded.svg',
        title:"Specialized Team",
        para:"With a potent team of specialized managers, engineers, supervisors, technicians, and domain experts, we collaborate closely, adhering to safety, cost, time schedule, and quality parameters. This synergy with our clients has resulted in a roster of esteemed recurring clientele"
    },
    {
        id:3,
        img:'./cells-duotone-rounded.svg',
        title:"Vision and Commitment",
        para:"Our vision, driven by steady revenue growth, a skilled workforce, and cutting-edge technology, is to be a leading global provider of integrated electrical and instrumentation services. We are committed to delivering top-quality services while fostering a culture of a healthy workplace environment and sustainable project management"
    }
]