import './Services.scss';
import ImageComponent from '../../ImageComponent';
import { SvgIconArrow } from '../../svgs';
import { Link } from 'react-router-dom';
import React, { useState } from 'react';
import { data } from './ServicesData';
import { Helmet } from "react-helmet";
import BannerInnerSmall from "./../BannerInnerSmall";


const Services = () => {
  const [selectedImage, setSelectedImage] = useState(null);

  const handleClick = (image) => {
    setSelectedImage(image);
    document.querySelector('body').style.overflowY = 'hidden';
    document.body.classList.add('overflow');
  };

  const handleClose = () => {
    setSelectedImage(null);
    document.querySelector('body').style.overflowY = 'auto';
    document.body.classList.remove('overflow');
  };

  return (
    <>
    <Helmet>
        <title>Our Services | Comprehensive Electrical Engineering Solutions</title>
        <meta
        name="description"
        content="Explore our wide range of electrical engineering services at E&I Engineers. From turnkey projects and earthing electrode installation to industrial wiring and maintenance, we deliver expert solutions tailored to your needs."
        />
    </Helmet>
    <BannerInnerSmall
      smTitle="Our Services at E&I Engineers"
    />
    <section className="servicesPage">
      
      {data.map((image) => (
        <div key={image.id} className="servicesItem" onClick={() => handleClick(image)}>
          <img src={image.img} alt={image.title} />
          <div className='txtPan opacityF2T slide-left'>
            <h4>{image.title}</h4>
            <Link><ImageComponent SvgIcon={SvgIconArrow} className="arrowIcon" width="32px" height="32px" /></Link>
          </div>
        </div>
      ))}

      {selectedImage && (
        <div className="modal" onClick={handleClose}>
          <div className="modal-content slide-fwd-top opacityF2T" onClick={(e) => e.stopPropagation()}>
            <span className="close" onClick={handleClose}>&times;</span>
            <div className='modal-contentInner'>
              <div className='picPan slide-left'>
                <img src={selectedImage.img} alt={selectedImage.title} />
              </div>
              <div className='textPan slide-right'>
                <h2>{selectedImage.title}</h2>
                <p>{selectedImage.description}</p>
              </div>
            </div>

          </div>
        </div>
      )}
    </section>
    </>
    
  );
};

export default Services;
