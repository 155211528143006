// Direct Contracts
export const data1 = [
  {
    img: './images/projects/MARUTI-ISPAT-Mantralayam.jpeg',
    // ROLLING-MILL-at-Mantralayam.jpeg
    heading: "Maruti Ispat and Energy Pvt Ltd",
    place: "Mantralayam, Andhra Pradesh",
    description:
      "E&I Engineers successfully executed two significant projects at Maruti Ispat and Energy Pvt Ltd in Mantralayam, Andhra Pradesh. We completed the SMS Electrical Erection and Commissioning, ensuring seamless integration and functionality. Additionally, we handled the Rolling Mill Electrical Erection and Commissioning, demonstrating our expertise in large-scale industrial projects.",
  },
  {
    img: './images/projects/SRI-LAXMI-TULASI-AGRO-PAPER-at-ashwaraopeta.jpg',
    heading: " Sri Laxmi Tulasi Agro Paper Pvt Ltd",
    place: "Telangana,",
    description:
      "For the past four years, E&I Engineers has been supplying manpower to the process department at Sri Laxmi Tulasi Agro Paper Pvt Ltd, located in Telangana. Our ongoing commitment and reliable workforce have contributed to the smooth operation and efficiency of their processes.",
  },
  {
    img: './images/projects/Sree-Rayalaseema-Alkalies-and-Allied-chemicals-Ltd-Kurnool.jpg',
    heading: " Sree Rayalaseema Alkalies and Allied Chemicals Ltd",
    place: "Kurnool, Andhra Pradesh",
    description:
      "E&I Engineers provides specialized manpower to the electrical department at Sree Rayalaseema Alkalies and Allied Chemicals Ltd in Kurnool, Andhra Pradesh. Our skilled team ensures the continuous and effective operation of their electrical systems.",
  },
];
// ### Subcontracts
export const data2 = [
  {
    img: './images/projects/MopaFamilies-rehabilitated-to-Kasarverne.jpg',
    heading: "MOPA",
    place: "Goa",
    description:
      "We successfully executed the wiring job for 14 houses, demonstrating our proficiency in residential electrical installations.",
  },
  {
    img: "./images/projects/Coal-compaction-station.jpeg",
    heading: " Coal Compaction Station No#01",
    place: "Goa",
    description:
      "We handled the erection and commissioning, ensuring efficient and reliable operations.",
  },
  {
    img: './images/projects/BLAST-FURNACE-NO-03-at-Goa.jpeg',
    heading: " Blast Furnace No#03 628m3",
    place: "Goa",
    description:
      "Our team managed the E&I dismantling, erection, and commissioning, showcasing our ability to handle complex industrial tasks.",
  },
  {
    img: './images/projects/BLAST-FURNACE-NO-03-Bokaro.jpeg',
    heading: " Blast Furnace No#03 470m3",
    place: "Bokaro",
    description:
      "Similar to the Goa project, we completed the E&I dismantling, erection, and commissioning, reinforcing our expertise in large-scale industrial projects.",
  },
  {
    img: './images/projects/SINTER-PLANT-at-Goa.jpeg',
    heading: " Vedanta Ltd",
    place: "Goa",
    description:
      "E&I Engineers provided electrical maintenance for the Sinter Plant 1x75m2 at Vedanta Ltd in Goa. Our maintenance services ensured the plant's operations remained efficient and uninterrupted.",
  },
  {
    img: './images/projects/POWER-PLANT-at-khopoli-mumbai.jpeg',
    heading: " Uttam Galva Steels Ltd",
    place: "Mumbai",
    description:
      "At Uttam Galva Steels Ltd in Khopoli, Mumbai, E&I Engineers was responsible for the electrical maintenance of the Power Plant 2x30 MW. Our work contributed to the plant's reliable and efficient power generation.",
  },
  {
    img: './images/projects/FIRST-SOLAR-UNDER-STERLING-ELECTRO-at-Channai.jpg',
    heading: "Sterling Electro Enterprises Pvt Ltd",
    place: "Pillaipakkam, Chennai",
    description:
      "For the PV Manufacturing Unit at First Solar, located in Pillaipakkam, Chennai, E&I Engineers delivered electrical manpower services under Sterling Electro Enterprises Pvt Ltd. Our skilled personnel ensured the unit's electrical systems operated smoothly.",
  },
  {
    img: './images/projects/Alpha-marineLTD-at-Nellore.jpeg',
    heading: " Ragas Electrical & Infra Projects",
    place: "Nellore",
    description:
      "E&I Engineers undertook the electrical erection and commissioning for Alpha Marine Ltd in Nellore under Ragas Electrical. Our expertise facilitated the successful completion of this maritime project.",
  },
  {
    img: './images/projects/Bharati-CementLTD-kadapa.jpeg',
    heading: " Universal Power Tech",
    place: "Kadapa",
    description:
      "Under Universal, E&I Engineers completed the erection and commissioning of the Biomass Shed at Bharati Cement Ltd in Kadapa. Our work supported the expansion and operational efficiency of their biomass facilities.",
  },
];

// --------------
// These detailed projects underscore E&I Engineers' capability to deliver high-quality electrical and engineering services across a variety of industries and settings.
