import { useEffect } from 'react';

const HeadStyle = ({ styles }) => {
  useEffect(() => {
    // Create a style element
    const styleElement = document.createElement('style');
    styleElement.type = 'text/css';
    styleElement.id = 'dynamic-styles';

    // Add the styles to the style element
    if (styleElement.styleSheet) {
      styleElement.styleSheet.cssText = styles;
    } else {
      styleElement.appendChild(document.createTextNode(styles));
    }

    // Append the style element to the head
    document.head.appendChild(styleElement);

    // Cleanup function to remove the style element when the component unmounts
    return () => {
      document.head.removeChild(styleElement);
    };
  }, [styles]);

  return null;
};

export default HeadStyle;
