import './Services.scss';
import ImageComponent from '../../../ImageComponent';
import { SvgIconServices1, SvgIconServices2, SvgIconServices3, SvgIconServices4 } from '../../../svgs';

function Services() {
    return (
        <>
        <section className='services containerSpacing-3'>
            <h2 id='headTitle'>
                <span id='title1'>Our Services</span>
                <span id='title2'>Custom electrical & instrumentation solutions for your successful projects</span>
            </h2>
            <div className='services-info'>
                <ul className='services-info__item'>
                    <li className='services-info__item__list'>
                        <div className='services-info__item__list__img'>
                            <ImageComponent SvgIcon={SvgIconServices1} width="50px" height="50px" />
                        </div>
                        <h3 className='services-info__item__list__title'>
                        Electrical Installation and Maintenance
                        </h3>
                        <p className='services-info__item__list__text'>
                        Our team provides reliable electrical installation and maintenance services, ensuring safety and efficiency. We handle wiring, upgrades, and emergency repairs to keep your operations running smoothly.
                        </p>
                    </li>
                    <li className='services-info__item__list'>
                        <div className='services-info__item__list__img'>
                            <ImageComponent SvgIcon={SvgIconServices2}  width="50px" height="50px" />
                        </div>
                        <h3 className='services-info__item__list__title'>
                        Instrumentation and Control Systems
                        </h3>
                        <p className='services-info__item__list__text'>
                        We design, install, and maintain precise instrumentation and control systems for better industrial process monitoring and automation, improving efficiency and accuracy.
                        </p>
                    </li>
                    <li className='services-info__item__list'>
                        <div className='services-info__item__list__img'>
                            <ImageComponent SvgIcon={SvgIconServices3} width="50px" height="50px" />
                        </div>
                        <h3 className='services-info__item__list__title'>
                        Project Management and Manpower Solutions
                        </h3>
                        <p className='services-info__item__list__text'>
                        We offer skilled professionals and comprehensive project management to ensure your projects are completed on time and to high standards, from planning to completion.
                        </p>
                    </li>
                    <li className='services-info__item__list'>
                        <div className='services-info__item__list__img'>
                            <ImageComponent SvgIcon={SvgIconServices4} width="50px" height="50px" />
                        </div>
                        <h3 className='services-info__item__list__title'>
                        Electrical Safety and Compliance
                        </h3>
                        <p className='services-info__item__list__text'>
                        We conduct thorough electrical safety audits and help you meet regulatory standards. Our experts identify hazards and provide recommendations to enhance safety.
                        </p>
                    </li>
                   
                    <li className='services-info__item__list last'>
                        <h3 className='services-info__item__list__title'>
                            Call us for Quote
                        </h3>
                        <p className='services-info__item__list__text'>
                        Contact us today for a personalized quote tailored to your needs. Our team is ready to provide you with detailed information and competitive pricing for all our services
                        </p>
                        <div className='services-info__item__list__btn'>
                            <a href="tel:+919347969458">+91 93 47 96 94 58</a><br />
                            <a href="tel:+919059239458">+91 90 59 23 94 58</a>
                        </div>
                    </li>
                </ul>
            </div>
        </section>
        </>
    )
}
export default Services;