import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './LogoSlider.scss';
const LogoSlider = () => {
  const settings = {
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 0,
    speed: 8000,
    pauseOnHover: false,
    cssEase: 'linear',
    arrows:false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  };

  const logos = [
    { src: './LogoSlider/1.jpg', alt: 'Client 1' },
    { src: './LogoSlider/2.jpg', alt: 'Client 2' },
    { src: './LogoSlider/3.jpg', alt: 'Client 3' },
    { src: './LogoSlider/4.jpg', alt: 'Client 4' },
    { src: './LogoSlider/5.jpg', alt: 'Client 5' },
    { src: './LogoSlider/6.png', alt: 'Client 6' },
    { src: './LogoSlider/7.png', alt: 'Client 7' }
  ];

  return (
    <section className="logo-slider containerSpacing-3">
      <div id='headTitle'>
      <h2 id='title1'>Our Trusted Partners</h2>
      </div>
      <Slider {...settings}>
        {logos.map((logo, index) => (
          <div key={index} className="logo-slide">
          <img src={logo.src} alt={logo.alt} loading="lazy" />
        </div>
        ))}
      </Slider>
    </section>
  );
};

export default LogoSlider;