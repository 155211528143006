import React from "react";
import "./Projects.scss";
import { data1,data2 } from "./ProjectsData";
import ProjectsContact from "./ProjectContact/ProjectContact";
import { Helmet } from "react-helmet";

const Projects = () => {
  return (
    <div className="projectMain">
    <Helmet>
      <title>Our Projects | E&I Engineers' Success Stories</title>
      <meta
      name="description"
      content="Browse through our successful projects at E&I Engineers. See how we have delivered exceptional electrical engineering solutions, from substation erection to high mast erection and maintenance services."
      />
    </Helmet>
    <section className="projectSection">
    <h1 className="title  slide-bottom">E&I  Engineers  Projects</h1>
      <div className="directContracts">
      <h2 className="subheading slide-right">Direct Contracts</h2>
      {data1.map((project, index) => (
        <React.Fragment key={index}>
          <div className="projectColumn">
            <div className="picPan opacityF2T slide-left">
              <img
                src={project.img}
                alt={project.heading}
                className="project-image"
              />
            </div>
            <div className="textPan slide-left">
              <h2 className="heading">{project.heading}</h2>
              <h3>{project.place}</h3>
              <p className="project-description">{project.description}</p>
            </div>
          </div>
          {index !== data1.length - 1 && <hr />}
        </React.Fragment>
      ))}
      </div>
      <div className="subContracts">
      <h2 className="subheading slide-right">Sub-Contracts</h2>
      {data2.map((project, index) => (
        <React.Fragment key={index}>
          <div className="projectColumn">
            <div className="picPan opacityF2T slide-left">
              <img
                src={project.img}
                alt={project.heading}
                className="project-image"
              />
            </div>
            <div className="textPan slide-left">
              <h2 className="heading">{project.heading}</h2>
              <h3>{project.place}</h3>
              <p className="project-description">{project.description}</p>
            </div>
          </div>
          {index !== data2.length - 1 && <hr />}
        </React.Fragment>
      ))}
      </div>
    </section>
    <ProjectsContact />
    </div>
  );
};

export default Projects;
