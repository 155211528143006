export const data=[
    {   id:1,
        title:'Turnkey Electrical Projects',
        description:'EI Engineers is Turnkey Gov Licensed Electrical contractors; we undertake turnkey projects of Electrical Installations including, Engineering, Installation, Testing & Commissioning for Transmission Lines, Power & Distribution Transformer Centers, Electrification work, & Erection of Substations etc.',
        img:'./images/services/Turnkey-Electrical-Projects.jpeg'},
    {   id:2,
        title:'Earthing Electrode Installation',
        description:'EI Engineers has a fully trained technical team that can design earthing sizes and locations, as well as install earthing with a bore vehical. We take on earthing maintenance jobs where we check all earthing properly and include it after inspection of the earth pit for adding earth enhance compound or completely replacing with new earthing.',
        img:'./images/services/EarthingElectrode-Installation.jpg'},
    {   id:3,
        title:'Underground Cable Laying',
        description:'E&I ENGINEERS Rich experience of cable laying in many reputed industries.Technical experties for selection cable to execution of laying Cable laying with proper equipments for care of cable Exprinaced team of technical project engineer, supervior and skilled lebours Exprinaced team of cable jointing kitFoult finding technical experties Proper specification exacvation and laying of cableJeb, exacavators 220 d, hammer, tractor, cable pulling machine, rollers, cable drum stand are avilable with our team as per requirement Expert team for horizonatal boaring for underground cabling in India',
        img:'./images/services/Underground-Cable-Laying.jpg'},
    {   id:4,
        title:'Isolator maintenance service',
        description:'11KV, 33KV, 132KV, 220KV Isolator maintenance services',
        img:'./images/services/Isolator-maintenance-service.jpeg'},
    {   id:5,
        title:'Fixing of Jointing Kits',
        description:'We are offering a quality range of Heat Shrinkable Terminations Joints for Medium Voltage (MV), having wide application in varied industries. Heat Shrinkable Terminations Joints for Medium Voltage (MV), having low XLPE/PILC/PVC Low Voltage Cables ranging from 6.6 KV up to 36KV.We have well-trained and technical sound manpower for Jointing the Heat Shrinkable End Termination kits',
        img:'./images/services/Fixing-of-Jointing-Kits.jpg'},
    {   id:6,
        title:'Substation Erection Service',
        description:'Erection and Maintenance of Power Transformer,Erection of Current Transformers Potential Transformer ( CT PT ),Erection of Circuit Breaker,Erection of Lightening Arrestor,Erection of Isolator,Erection of C & R Panels,Laying of control cables in Substation,Erection of Substation Gantry,Erection of Substation Connectors,Erection Substation Earthing,Erection of Foundation civil work of Substation,Battery Charger and Battery bank,Lighting system of Electrical Substation,Erection of GIS Substation,Replacement of Substation equipment’s',
        img:'./images/services/Substation-Erection-Service.jpeg'},
    {   id:7,
        title:'Maintenance Of VCB SF6',
        description:'EI Engineers has a highly experienced and expert technical team for maintenance of High Voltage and EHV (Extra High Voltage) equipment. such as Load Break Switch ,VCB (Vacuum circuit breaker ) , SF6 breaker from Voltage level 11kv,22kv,33kv,132kv upto 220 kv We fallow standard method for maintenance for electrical high voltage and Extra high voltage equipment',
        img:'./images/services/Maintenance-Of-VCB-SF6.jpg'},
    {   id:8,
        title:'Erection Of Bus Duct',
        description:'EI Engineers having experienced Man power in Panel and bus duct work , starting from designing of bus duct panel to charging and commissioning of bus duct panel we gave our best on site . Our team is well-equipped with tools and tackles as well as all safety equipment’s and devices. After execution of bus duct panel work we take all related testing of bus bar to confirm its accuracy . Out team having Contact resistance measurement testing kit upto 100 amp , Insulation tester , High voltage testing kit etc.',
        img:'./images/services/Erection-Of-Bus-Duct.jpeg'},
        {
            id:9,
            title:"Overhead Power Line Installation",
            description:'Overhead power line installation involves the setup and maintenance of electrical power lines that are suspended above the ground on utility poles or transmission towers. Electrical and Instrumentation (EI) engineers play a crucial role in this process, ensuring the safe and efficient transmission of electricity from power plants to homes and businesses.',
            img:'./images/services/Overhead-Power-Line-Installation.jpg'
        },
        {
            id:10,
            title:"Electrical Panel Maintenance",
            description:'Electrical panel maintenance is crucial for the safe and efficient operation of electrical systems in residential, commercial, and industrial settings. EI (Electrical and Instrumentation) engineers play a vital role in ensuring that electrical panels are properly maintained to prevent failures, reduce downtime, and ensure safety.',
            img:'./images/services/Electrical-Panel-Maintenance.jpg'
        },
        {
            id:11,
            title:"High Mast Erection & Maintenance Services",
            description:'EI Engineers provide professional installation and maintenance services for high mast lighting solutions. It specializes in the installation and maintenance of high mast lighting poles up to 30 meters, along with the necessary hardware, fixtures, and fittings. The team is highly experienced in the latest trends and techniques in the field, and strives to ensure a seamless experience for its clients.The first step in the installation process is to assess and prepare the site for installation. Balaji Engineers’ team of experts inspects the site and prepares it by ensuring the soil is suitable for installation and the poles are placed at the correct distances. Next, the civil plinths for installation are excavated, and the poles are erected using cranes. Once the poles are placed in position, expert technicians mount the light fixtures and wiring for optimal performance.',
            img:'./images/services/High-mast-erection-and-maintenance-service.jpg'
        },
        {
            id:12,
            title:"All types of HT and LT Panel Installation",
            description:'The installation of High Tension (HT) and Low Tension (LT) panels is a critical task in the electrical infrastructure of various facilities, including residential, commercial, and industrial settings. Electrical and Instrumentation (EI) engineers play a pivotal role in the design, installation, and commissioning of these panels, ensuring they operate safely and efficiently.',
            img:'./images/services/All-types-of-HT-and-LT-Panel-Installation.jpg'
        },
        {
            id:13,
            title:"Erection of Transformers",
            description:'The erection of transformers is a critical task in electrical infrastructure, involving the installation of equipment that steps up or steps down voltage levels for efficient power transmission and distribution. Electrical and Instrumentation (EI) engineers are responsible for overseeing the entire process to ensure safety, efficiency, and compliance with standards.',
            img:'./images/services/Erection-of-Transformers.jpeg'
        },
        {
            id:14,
            title:"Manpower Services for Induistries",
            description:'Manpower services for industries provided by Electrical and Instrumentation (EI) engineers encompass a range of tasks related to the deployment, management, and optimization of skilled personnel in various industrial settings. These services ensure that electrical and instrumentation systems are installed, maintained, and operated effectively, supporting the overall efficiency and safety of industrial operations.',
            img:'./images/services/Manpower-Services-for-Induistries.jpeg'
        },
        {
            id:15,
            title:"Electrical projects:",
            description:'We undertake turnkey HT & LT electrical contracts involving supply, installation, testing and commissioning of switchyards, substations, internal & external electrical works for industrial, commercial and infrastructure projects, transmission line, transformers panel switchgear, diesel generators, ups, inventory and battery chargers, cables and transmissions, external illumination system consisting of street light, high mast, solar lighting, public addresses, cctv system, building management system, liasoning assistance, telephone, data cable networking, fire alarm system and fire fighting system and clean room installations for Pharmaceutical and food processing units.',
            img:'./images/services/Electrical-projects.jpg'
        },
        {
            id:16,
            title:"Instrumentation projects:",
            description:'We undertake turnkey Instrumentation contracts involving supply, installation, testing and commissioning of process control instrumentation, pneumatic control systems, electronic control systems, temperature/pressure/flow/level instruments, analytical instruments, PLC & SCADA operated control system, instrumentation hardware materials like ss/copper tube fitting, impulse pipe & fitting, cable tray, signal & contro cable, junction box, air manifold, cable gland, calibration of all types of process control instrumentation, hot and cold loop checking and all types of measuring instruments.',
            img:'./images/services/Instrumentation-projects.jpeg'
        },
    
];