import './WhyUs.scss';

function whyUs() {
    return (
        <section className='whyUs containerSpacing-3'>
            <h2 id='headTitle'>
                <span id='title1'>why us</span>
                <span id='title2'>we are here to grow your<span>business exponentially</span></span>
            </h2>
            <div className='features-info'>
                <div className='col-1'>
                <div className='feature-item'>
                    <div className='icon'>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" color="#ffffff" fill="none">
    <path d="M5 16L10 13M14 11L19 8M12 5V10M12 14V19M5 8L10 11M14 13L19 16" stroke="currentColor" stroke-width="1.5" stroke-linejoin="round" />
    <path d="M20.5 9.00001V14.5M13.5 20.5L19 17.5M4.5 17.5L10.5 20.5M3.5 15V9.00001M4.5 6.5L10.5 3.5M19.5 6.5L13.5 3.5" stroke="currentColor" stroke-width="1.5" stroke-linejoin="round" />
    <circle cx="12" cy="3.5" r="1.5" stroke="currentColor" stroke-width="1.5" stroke-linejoin="round" />
    <circle cx="12" cy="20.5" r="1.5" stroke="currentColor" stroke-width="1.5" stroke-linejoin="round" />
    <circle cx="3.5" cy="7.5" r="1.5" stroke="currentColor" stroke-width="1.5" stroke-linejoin="round" />
    <circle cx="20.5" cy="7.5" r="1.5" stroke="currentColor" stroke-width="1.5" stroke-linejoin="round" />
    <circle cx="20.5" cy="16.5" r="1.5" stroke="currentColor" stroke-width="1.5" stroke-linejoin="round" />
    <circle cx="3.5" cy="16.5" r="1.5" stroke="currentColor" stroke-width="1.5" stroke-linejoin="round" />
    <path d="M12 9.75L14 10.875V13.125L12 14.25L10 13.125V10.875L12 9.75Z" stroke="currentColor" stroke-width="1.5" stroke-linejoin="round" />
</svg>
                    </div>
                    <div className='txtPan'>
                        <h3>Best in industry</h3>
                        <p>Leading industry with exceptional performance and unmatched standards, showcasing leadership and innovation. It sets a high benchmark, emphasizing superior quality and expertise in the sector.</p>
                        </div>
                </div>
                
                <div className='feature-item'>
                    <div className='icon'>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" color="#ffffff" fill="none">
    <path d="M4.5 14.5C4.5 10.3579 7.85787 7 12 7C16.1421 7 19.5 10.3579 19.5 14.5C19.5 18.6421 16.1421 22 12 22C7.85787 22 4.5 18.6421 4.5 14.5Z" stroke="currentColor" stroke-width="1.5" />
    <path d="M9 14.5C9 12.8431 10.2952 11.5 11.8929 11.5H12.1071C13.7048 11.5 15 12.8431 15 14.5C15 16.1569 13.7048 17.5 12.1071 17.5H11.8929C10.2952 17.5 9 16.1569 9 14.5Z" stroke="currentColor" stroke-width="1.5" />
    <path d="M13.5596 2L10.999 7M17.9995 2L15.1776 7.60217" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" />
    <path d="M10.4399 2L12 4.59405M6 2L8.82144 7.5" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" />
</svg>
                    </div>
                    <div className='txtPan'>
                        <h3>Excellence and Innovation</h3>
                        <p>Explore our industry-leading innovations, highlighting our commitment to excellence and cutting-edge solutions that set us apart in the electrical and instrumentation engineering field.</p>
                    </div>
                </div>
                </div>
                <div class="image col-2">
        <img src='./images/about/about-why-vertical.jpeg' alt="aboutUs-why-We Are Here To Grow Your Business Exponentially" />
      </div>
      <div className='col-3'>
                <div className='feature-item'>
                    <div className='icon'>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" color="#ffffff" fill="none">
    <path d="M12 7.5C12 9.433 10.433 11 8.5 11C6.567 11 5 9.433 5 7.5C5 5.567 6.567 4 8.5 4C10.433 4 12 5.567 12 7.5Z" stroke="currentColor" stroke-width="1.5" />
    <path d="M13.5 11C15.433 11 17 9.433 17 7.5C17 5.567 15.433 4 13.5 4" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" />
    <path d="M13.1429 20H3.85714C2.83147 20 2 19.2325 2 18.2857C2 15.9188 4.07868 14 6.64286 14H10.3571C11.4023 14 12.3669 14.3188 13.1429 14.8568" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M19 14V20M22 17L16 17" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" />
</svg>
                    </div>
                    <div className='txtPan'>
                        <h3>Professional staff</h3>
                        <p>Discover our skilled professional staff dedicated to excellence in industrial solutions, combining years of expertise with a commitment to innovation and client satisfaction.</p>
                    </div>
                </div>
                <div className='feature-item'>
                    <div className='icon'>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" color="#ffffff" fill="none">
                            <path d="M9.1585 5.71223L8.75584 4.80625C8.49256 4.21388 8.36092 3.91768 8.16405 3.69101C7.91732 3.40694 7.59571 3.19794 7.23592 3.08785C6.94883 3 6.6247 3 5.97645 3C5.02815 3 4.554 3 4.15597 3.18229C3.68711 3.39702 3.26368 3.86328 3.09497 4.3506C2.95175 4.76429 2.99278 5.18943 3.07482 6.0397C3.94815 15.0902 8.91006 20.0521 17.9605 20.9254C18.8108 21.0075 19.236 21.0485 19.6496 20.9053C20.137 20.7366 20.6032 20.3131 20.818 19.8443C21.0002 19.4462 21.0002 18.9721 21.0002 18.0238C21.0002 17.3755 21.0002 17.0514 20.9124 16.7643C20.8023 16.4045 20.5933 16.0829 20.3092 15.8362C20.0826 15.6393 19.7864 15.5077 19.194 15.2444L18.288 14.8417C17.6465 14.5566 17.3257 14.4141 16.9998 14.3831C16.6878 14.3534 16.3733 14.3972 16.0813 14.5109C15.7762 14.6297 15.5066 14.8544 14.9672 15.3038C14.4304 15.7512 14.162 15.9749 13.834 16.0947C13.5432 16.2009 13.1588 16.2403 12.8526 16.1951C12.5071 16.1442 12.2426 16.0029 11.7135 15.7201C10.0675 14.8405 9.15977 13.9328 8.28011 12.2867C7.99738 11.7577 7.85602 11.4931 7.80511 11.1477C7.75998 10.8414 7.79932 10.457 7.90554 10.1663C8.02536 9.83828 8.24905 9.56986 8.69643 9.033C9.14586 8.49368 9.37058 8.22402 9.48939 7.91891C9.60309 7.62694 9.64686 7.3124 9.61719 7.00048C9.58618 6.67452 9.44362 6.35376 9.1585 5.71223Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" />
                        </svg>
                    </div>
                    <div className='txtPan'>
                        <h3>24/7 Support</h3>
                        <p>We are here to provide 24/7 support, ensuring round-the-clock assistance for all your needs. Our dedicated team is always available to deliver prompt and reliable service, keeping your operations running smoothly.</p>
                    </div>
                </div>
                </div>
                
            </div>
        </section>
    )
}
export default whyUs;