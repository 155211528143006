import React from "react";
import { Link } from 'react-router-dom';
import "./Footer.scss";
import ImageComponent from '../ImageComponent';
import { SvgIconTwitter,SvgIconMailto,SvgIconLinkedin,SvgIcontWhatsup,SvgIcontPhone} from '../svgs';

function Footer() {
    return (
        <div className="footer">
            <div className="footer-container">
                <div className="footer-section">
                    <div className="cta-contents">
                        <Link to="/" className="logo">
                            <img src='./eandiengineers-logo-wt-blkbg.svg' alt="E&I Engineers" />
                        </Link>
                    </div>
                </div>
                <div className="footer-section">
                    <h3>Quick Links</h3>
                    <ul>
                        <li>
                            <Link to="/">Home</Link>
                        </li>
                        <li>
                            <Link to="/About">About</Link>
                        </li>
                        <li>
                        <Link to="/Services">Services</Link>
                        </li>
                        <li>
                        <Link to="/Contact">Contact Us</Link>
                        </li>
                    </ul>
                </div>
                <div className="footer-section">
                    <h3>Projects</h3>
                    <ul>
                        <li>
                        <Link to="/Projects">Direct Contracts</Link>
                        </li>
                        <li>
                        <Link to="/Projects">Sub-Contracts</Link>
                        </li>
                    </ul>
                </div>
                <div className="footer-section">
                    <h3>Follow Us</h3>
                    <ul className="social-media">
                    <Link to="https://x.com/eandiengineers" target="_blank"><ImageComponent SvgIcon={SvgIconTwitter} width="32px" height="32px" /></Link>
                    <Link to="mailto:azahar.shaikh3@gmail.com" target="_blank"><ImageComponent SvgIcon={SvgIconMailto} width="32px" height="32px" /></Link>
                    <Link to="https://www.linkedin.com/in/eandiengineers/" target="_blank"><ImageComponent SvgIcon={SvgIconLinkedin} width="32px" height="32px" /></Link>
                    <Link to="https://wa.me/9347969458" target="_blank"><ImageComponent SvgIcon={SvgIcontWhatsup} width="32px" height="32px" /></Link>
                    <Link to="tel:+919347969458" target="_blank"><ImageComponent SvgIcon={SvgIcontPhone} width="32px" height="32px" /></Link>
                    </ul>
                </div>
            </div>
            <div className="footer-bottom">
                
                <p>&copy; 2024 E&I ENGINEERS. All rights reserved.</p>
                <p>
                Maintenance by  <Link to="https://askgraphix.in" rel="noopener" target='_blank'> www.aSkGraphix.in <img src="./askgraphix.svg" alt="aSkGraphix.in" />
                </Link></p>
            </div>
        </div>
    );
}

export default Footer;