import React from "react";
import './BannerInner.scss'

function BannerInner({imgLarge,imgSmall,imgTitle}) {
    return (
        <h1 className="bannerInner opacityF2T">
            <picture>
                <source media="(min-width:990px)" srcset={imgLarge} />
                <source media="(min-width:100px)" srcset={imgSmall} />
                
                <img src={imgLarge} alt={imgTitle} title={imgTitle} />
            </picture>
            <div className="textPanel slide-left">
                <h1>{imgTitle}</h1>
            </div>
        </h1>
    )
}

export default BannerInner;