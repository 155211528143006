import React from "react";
import '../../BannerInner.scss';
import BannerInner from "../../BannerInner";

function AboutAbnner() {
    return (
        <section className='bannerMain'>
            <BannerInner 
                imgLarge='./images/about/about-banner-large.jpeg'
                imgSmall="./images/about/about-banner-small.jpeg"
                imgTitle="Discover E&I Engineers"
            />
        </section>
    )
}

export default AboutAbnner;