
import React from 'react';
import { Route, Routes } from 'react-router-dom';
import ScrollToTop from './Components/ScrollToTop.js';
import Header from './Components/Header/Header';
import Home from './Components/Mid/Home/Home';
import Services from './Components/Mid/Services/Services';
import Contact from './Components/Mid/Contact/Contact';
import Projects from './Components/Mid/Projects/Projects'
import About from './Components/Mid/About/About';
import GoToTop from './Components/GoToTop/GoToTop';
import Footer from './Components/Footer/Footer';
import ReactGA from "react-ga4";
import { createBrowserHistory } from 'history';
import TagManager from 'react-gtm-module';
import './App.scss';
import './General.scss';

const tagManagerArgs = {
  gtmId: 'G-HJD460ZC6B'
}
TagManager.initialize(tagManagerArgs);


ReactGA.initialize("G-HJD460ZC6B");
const history = createBrowserHistory();
history.listen(location => {
  ReactGA.set({ page: location.pathname });
  ReactGA.send(location.pathname);
});

function App() {

  return (
    <div className='container'>
      <ScrollToTop />
      <Header/>
      <Routes>
      <Route path="/" element={<Home />}/>
      <Route path='/About' element={<About />}/>
      <Route path="/Services" element={<Services />}/>
      <Route path="/Projects" element={<Projects />}/>
      <Route path="/Contact" element={<Contact />}/>
      </Routes>
      <Footer />
      <GoToTop />
    </div>
  );
}

export default App;