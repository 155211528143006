import React from "react";
import { Data } from "./Sec2Data";
import "./Sec2.scss";

function Sec2() {
  return (
    <section className="AboutSec2">
      <ul className="featuresSection">
        <li className="featuresSectionRepeat slide-bottom">
          <div className="col-2">
            <h2>E&I Engineer's: Your Trusted Turnkey Electrical Contractors</h2>
            <p>
              Welcome to E&I Engineer's, where we are committed to delivering
              top-notch electrical solutions for a variety of industrial and
              commercial needs. As a licensed government contractor, we
              specialize in turnkey projects encompassing engineering,
              installation, testing, and commissioning. Our expertise spans
              across:
            </p>
            <ul>
              <li>Power plant</li>
              <li>Sinter plant</li>
              <li>Cement industry</li>
              <li>Paper industry</li>
              <li>Chemical industry</li>
              <li>Oil and gas industry</li>
              <li>Rolling mill</li>
              <li>Tube mill</li>
              <li>Blast furnace</li>
            </ul>
            <p>
              Our team is equipped with highly trained technicians and advanced
              equipment to ensure efficient and safe project execution. Whether
              it’s maintenance of isolators, erection of bus ducts, or
              comprehensive earthing solutions, we provide reliable and
              professional services tailored to meet our clients' unique
              requirements.
            </p>
            <p>
              At E&I Engineer's, quality and safety are our top priorities. We
              adhere to stringent industry standards and employ cutting-edge
              technology to deliver exceptional results. Partner with us for all
              your electrical and instrumentation project needs, and experience
              unparalleled service and expertise.
            </p>
          </div>
        </li>
        {Data.map((item, index) => {
          return (
            // <>
            <li className="featuresSectionRepeat">
              <div className="col-1">
                <img src={item.img} alt="" />
              </div>
              <div className="col-2">
                <h2 key={item.id}>{item.title}</h2>
                <p key={item.id}>{item.para}</p>
              </div>
            </li>
            // {/* {index < Data.length - 1 && <hr />} */}
            // {/* </> */}
          );
        })}
      </ul>
    </section>
  );
}

export default Sec2;
