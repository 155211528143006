import React from "react";
import Slider from "react-slick";
import "./Gallery.scss";
import { data } from "../../../Mid/Services/ServicesData";
import { Link } from "react-router-dom";
import ImageComponent from '../../../ImageComponent';
import { SvgIconArrow } from '../../../svgs';

const Gallery = () => {
  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    arrows: true,
    slidesToShow: 4,
    slidesToScroll: 2,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          dots: false
        },
      },
    ],
  };

  return (
    <section className="gallery containerSpacing-3">
      <h2 id="headTitle">
        <span id="title1">Explore Our Services</span>
      </h2>
      <div className="content">
          <Slider {...sliderSettings} className="image-gallery">
            {data.map((image) => (
              <Link to="/Services" key={image.id}>
                <div className="image" style={{ backgroundImage: `url(${image.img})` }}>
                  <div className="overlay">
                  <p className="image-title">{image.title}</p>
                  <ImageComponent SvgIcon={SvgIconArrow} className="title-arrow"/>
                  </div>
                </div>
              </Link>
            ))}
          </Slider>
      </div>
      <div className="cta">
                    <Link to="/Services" className="learn-more">
                        <span className="circle" aria-hidden="true">
                            <span className="icon arrow"></span>
                        </span>
                        <span className="btnTxt">Read more</span>
                    </Link>
      </div>
    </section>
  );
};

export default Gallery;
