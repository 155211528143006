// src/components/ImageComponent.js
import React from 'react';

const ImageComponent = ({ SvgIcon, ...props }) => (
  <div>
    <SvgIcon {...props} />
  </div>
);

export default ImageComponent;
