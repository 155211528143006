import React from 'react';
import ContactComponent from '../../Contact/ContactComponent';
import "./HomeContact.scss";
const HomeContact = () => {
    return (
        <section className='contact containerSpacing-2'>
            <ContactComponent 
                title1='Request A Quote'
                title2="Need A Free Quote?"
                title3="Please Feel Free to Contact us"
            />
        </section>
    )
};

export default HomeContact;