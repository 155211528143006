import React from "react";
import HeadStyle from './HeadStyle';

const dynamicStyles = `
    .bannerInnerSmall {
    background-color: #e4e4e9;
    padding: 20px 0;
    width:100%;
    }
    .bannerInnerSmall .textPanel h1 {
    width: 100%;
    max-width: 600px;
    margin: 0;
    color: #253d43;
    padding: 0 5%;
    font-size: 2rem;
    }
    @media only screen and (max-width: 600px) {
    .bannerInnerSmall .textPanel h1 {
        font-size : 1.45rem;
        text-shadow: none;
    }
  `;

function BannerInner({smTitle}) {
    return (
        <div className="bannerInnerSmall">
            <HeadStyle styles={dynamicStyles} />
            <div className="textPanel slide-bottom">
                <h1>{smTitle}</h1>
            </div>
        </div>
    )
}

export default BannerInner;